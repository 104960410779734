.loader-wrapper {
  background: rgba(255, 255, 255, 0.8);
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateXY(-50%, -50%);
}

.loader-grid
{
  width: 75px;
  text-align: center;
}

.loader-grid-parent
{
  display: flex;
  height: 75vh;
  align-items: center;
  justify-content: center;
}