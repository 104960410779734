.tmt-table {
    width: auto;
    border: 3px solid #FFFFFF
}

.tmtBlockTable{
    min-width: 40%;
}

.legend_border{
    border: 3px solid #FFFFFF
}

.cell_spacing{
    padding: 5px !important;
    line-height: 1.30 !important;
}

.tmt_furnace{
    font-weight: bold;
    margin-right: 10px;
    margin-left: 20px;
    color: #435591
}

.tmt_furnace_value{
    padding: 0;
    display: "inline"
}

.fontdetail{
    font-Weight: bold
}

.gen_display{
    display: flex
}

.tmt_white_bg{
    background:#FFFFFF
}

.tmt_furn_bg{
    background-color: #F45858;
    width: 20px;
    height: 4px
}

.tmt_bl_bg{
    background:#f1f6f9
}

.tmt_lbl_bg{
    color:#435591
}

.tmt_red_bg{
    background-color: #ed7925;
    width: 20px;
    height: 4px
}

.tmt_coil_bg{
    background: #d2e1eb
}

.tmt_yel_bg{
    background-color: #FFF241;
    width: 20px;
    height: 4px
}

.tmt_furnace_header{
    font-weight:500;
    color: black;
}

.sel-furnace{
    width: 200px;
}

.furnace_summary{
    margin-right: 10px;
}