.privacy-policy {
    padding: 0 $side-padding;
    color: $secondary-color;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0px;

    .post-content {
        p {
            padding-bottom: 10px;
            margin-bottom: 10px;
            font-size: 1rem;
            line-height: 1.5;
        }
        
        strong {
            font-weight: 700;
        }

        h3 {
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: 600;
            text-transform: none;
            font-size: 1.125rem;
            line-height: 1.5;
        }

        h4 {
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.5;
            margin-bottom: 10px;
            margin-top: 0;
        }

        ul {
            display: block;
            list-style-type: disc;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 40px;
        }

        ul li {
            margin-bottom: 10px;
            font-size: 1rem;
        }
    }

    .header {
        text-transform: capitalize;
        font-weight: 400;
        font-size: 3.5rem;
        line-height: 1.2;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .offset-md-1 {
        margin-left: 8.33%;
        margin-top: 5%;
    }

    .col-md-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83%;
    }
    .col-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: auto;
    }
}
