.l-table {
  //background: yellow;  
  th,
  td {
    text-align: left;
    padding: rem-calc(2) rem-calc(16);
    min-width: 80px;
    max-width: 100px;    
  }  
}
.l-table-setting {
  //background: $linde-lightgrey;
  th,
  td {
    text-align: left;
    padding: rem-calc(2) rem-calc(16);
    white-space: nowrap;
  }
  .th:nth-child(1),
  .td:nth-child(1) {
    min-width: 100px;    
  }  
}
.l-table-summary {
  //background: yellow;  
  th,
  td {
    text-align: left;
    padding: rem-calc(2) rem-calc(5);    
  }
  .th:nth-child(1),
  .td:nth-child(1) {
    min-width: 100px;
    white-space: nowrap;  
  }
  .th:nth-child(2),
  .td:nth-child(2),
  .th:nth-child(3),
  .td:nth-child(3) {
    min-width: 60px;
  }
}
.l-table-overview-cot-cd {  
  th,
  td {
    text-align: left;
    padding: rem-calc(2) rem-calc(10);    
    white-space: nowrap;
  }
  .th:nth-child(1),
  .td:nth-child(1) {
    min-width: 100px;
  }
  .th:nth-child(2),
  .td:nth-child(2),
  .th:nth-child(3),
  .td:nth-child(3) {
    min-width: 70px;
  }
}

.l-table-overview-cd-cokethickness {  
  th,
  td {
    text-align: left;
    padding: rem-calc(2) rem-calc(10);  
    white-space: nowrap;
  }
  .th:nth-child(1),
  .td:nth-child(1) {
    min-width: 100px;
  }
  .th:nth-child(2),
  .td:nth-child(2),
  .th:nth-child(3),
  .td:nth-child(3) 
  {
    min-width: 65px;
  }
}

.l-table-ctmt {  
  th,
  td {
    text-align: left;
    padding: rem-calc(2) rem-calc(16);  
    white-space: nowrap;
  }
  th:nth-child(1),
  td:nth-child(1) {
    min-width: 100px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    min-width: 70px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    min-width: 70px;
  }
}
.l-table-overview-cip {  
  th,
  td {
    text-align: left;
    padding: rem-calc(2) rem-calc(16);    
    white-space: nowrap;
  }
  .th:nth-child(1),
  .td:nth-child(1) {
    min-width: 100px;
  }
  .th:nth-child(2),
  .td:nth-child(2)
  {
    min-width: 85px;
  }
  .th:nth-child(3),
  .td:nth-child(3) {
    min-width: 65px;
    min-width: 70px;
  }
}
.l-table-detaildata {  
  th,
  td {
    text-align: left;
    padding: rem-calc(2) rem-calc(10);    
  }
  th:nth-child(1),
  td:nth-child(1) {
    min-width: 86px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    min-width: 70px;
  } 
}
