.button {
  background-color: $primary-color;
  border-radius   : $borderRadius;
  color           : $secondary-color;
  display         : block;
  width           : 100%;
  min-width       : rem-calc(100);
  height          : $buttonHeight;
  line-height     : $buttonHeight;
  text-align      : center;

  &.disabled {
    background-color: rgba(150, 150, 150, 0.5);
    color           : rgba(0, 85, 145, 0.5);
    cursor          : no-drop;

    &:hover {
      background-color: rgba(150, 150, 150, 0.5);
      color           : rgba(0, 85, 145, 0.5);
    }
  }
  
  &.red {
    background-color: $colorError;
    color           : white;
  }

  &.margin-right {
    margin-right: rem-calc(10);
  }

  &.margin-top {
    margin-top: rem-calc(10);
  }

}

button.button,
a.button {
  text-decoration: none;
  transition     : all 0.25s ease-in-out;

  &:hover {
    background-color: $secondary-color;
    color           : white;
  }
}

button.button {
  cursor : pointer;
  border : none;
  display: inline-block;
  width  : auto;
  padding: 0 rem-calc(8);
}

button.button:disabled {
  background-color: $disabled-color;
  cursor          : not-allowed;
  pointer-events  : all !important;
}