button.button.feedstockbutton {
    margin-bottom: 10px;
}

.right-container {
    margin-top: 15px;
}

.lblRecycle {
    text-align: right;

}

.scenario-running {
    overflow: visible;
    background-color: rgba(128, 128, 128, 0.486);
    z-index: 1;
}

.pp-circular-progress {
    top: 40%;
    position: fixed;
    z-index: 2;
}

.btnStopScenario {
    position: fixed;
    z-index: 2;
}

.bar-loader {
    position: fixed;
    z-index: 2;
    top: 51%;
}

.dispOutput {
    width: 50%;
    float: left;
}

.outputTitle {
    text-align: center;
    padding: 10px;
}

.outputTable {
    padding: 15px;

    .td,
    .th {
        padding: 5px;
    }
}

.output {
    width: 100%;
    margin-left: 15px;
}

.right-alignment {
    flex: auto;
    flex-direction: row-reverse;
}

.scenario-button {
    &.ERROR {
        background-color: red;
        color: white;
    }

}