$contentWidth: 1336px;

.page-container
{
  position: relative;
}

.displ-hide
{
  display: none;
}
.overall-tisza {
  position: absolute;
  background-image: none;
  width: $contentWidth;
  height: 930px;

  .furnace-overview-tisza {
    background-repeat: no-repeat;
    position: relative;
    width: $contentWidth;
    height: 835px;
    color: rgb(51, 51, 51);    

    .furnace-overview--wrapper {
      position: relative;
      width: 100%;
      height: 835px;
      background-repeat: no-repeat;
    }

    .furnace-img-1{
      background-image: url("../images/tisza/Furnace_Overview_Furnaces_1.svg");
    }
    .furnace-img-2{
      background-image: url("../images/tisza/Furnace_Overview_Furnaces_2.svg");
    }
    .furnace-img-3{
      background-image: url("../images/tisza/Furnace_Overview_Furnaces_3.svg");
    }
    .furnace-img-4{
      margin-left:5px;
      background-image: url("../images/tisza/Furnace_Overview_Furnaces_4.svg");
    }
   
    .boild_feed_water_flow {
      position: absolute;
      top: 4.5%;
      right: 72.5%;
      text-align: right;
    }

    .boild_feed_water_desuperheat_flow
    {
      position: absolute;
      top: 7.9%;
      right: 67%;
      text-align: right;
    }

    .boild_feed_water_press {
      position: absolute;
      top: 4.5%;
      right: 83.7%;
      text-align: right;
    }

    .boild_feed_water_temp {
      position: absolute;
      top: 4.5%;
      right: 78.7%;
      text-align: right;
    }

    .feed_flow_ad {
      position: absolute;
      top: 18.6%;
      right: 67%;
      text-align: right;

      
    }

    .feed_flow_tag{
      position: absolute;
      top: 15%;
      right: 69%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_flow_tag_1{
      position: absolute;
      top: 26%;
      right: 80.3%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    
    .feed_flow_pres{
      position: absolute;
      top: 15.5%;
      right: 85.3%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_flow_temp{
      position: absolute;
      top: 15.5%;
      right: 80.3%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    } 
    
    .feed_flow2_tag{
      position: absolute;
      top: 15%;
      right: 40%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }
    .feed_flow2_tag_1{
      position: absolute;
      top: 26%;
      right: 28.7%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    
    .feed_flow2_pres{
      position: absolute;
      top: 15.5%;
      right: 29.7%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_flow2_temp{
      position: absolute;
      top: 15.5%;
      right: 24.7%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    } 
    

    .feed_press_ad {
      position: absolute;
      top: 18.6%;
      right: 84.1%;
      text-align: right;
    }

    .feed_temp_ad {
      position: absolute;
      top: 18.6%;
      right: 78.7%;
      text-align: right;
    }

    .dilut_pres_ad {
      position: absolute;
      top: 40.6%;
      right: 83.6%;
      text-align: right;
    }

    .dilut_eh {
      position: absolute;
      top: 41%;
      right: 28.6%;
      text-align: right;
    }

    .dilut_flow_e_h
    {
      position: absolute;
      top: 30.6%;
      right: 38.2%;
      text-align: right;
    }
    
    .dilut_flow_ad_cold {
      position: absolute;
      top: 32%;
      right: 73.6%;
      text-align: right;
    }
    .dilut_flow_ad_hot_new {
      position: absolute;
      top: 41%;
      right: 63.6%;
      text-align: right;
    }
    .dilut_flow_ad_hot_e_h
    {
      position: absolute;
      top: 41%;
      right: 38.2%;
      text-align: right;
    }
    
    .feed_flow_ad_1 {
      position: absolute;
      top: 29.6%;
      right: 78.6%;
      text-align: right;
    }

    .dilut_temp_ad {
      position: absolute;
      top: 40.6%;
      right: 78.7%;
      text-align: right;
    }

    .fluegas2_temp {
      position: absolute;
      top: 46.6%;
      right: 89.7%;
      text-align: right;
    }

    
    .boild_feed_water_desuper {
      display: none;
      position: absolute;
      top: 53.0%;
      right: 83.2%;
      text-align: right;
    }

    .bundle3_temp{
      position: absolute;
      top: 57.8%;
      right: 84.2%;
      text-align: right;
    }

    .bundle4_temp{
      position: absolute;
      top: 57.8%;
      right: 79.1%;
      width: rem-calc(44);
      text-align: right;
    }


    .cg_pot_temp_ad {
      position: absolute;
      top: 69.7%;
      right: 78.7%;
      text-align: right;
    }

    .cg_cot_temp_ad {
      position: absolute;
      top: 69.7%;
      right: 73%;
      text-align: right;
    }

    .bundle_press_ad {
      position: absolute;
      top: 58.3%;
      right: 65.8%;
      text-align: right;
    }

    .bundle5_box1_temp_htc2_in {
      position: absolute;
      top: 58%;
      right: 71%;
      text-align: right;
    }

    .bundle_temp_ad {
      position: absolute;
      top: 58.3%;
      right: 61.2%;
      text-align: right;
    }

    .excess_air_ad_o2{
      position: absolute;
      top: 56.3%;
      right: 72.1%;
      text-align: right;
    }

    .flue_gas_press {
      position: absolute;
      top: 66%;
      right: 54.5%;
      text-align: right;
    }

    .flue_gas_ad_temp {
      position: absolute;
      top: 66%;
      right: 49.5%;
      text-align: right;
    }

    .fired_heat {
      position: absolute;
      top: 78%;
      right: 52%;
      text-align: right;
    }

    .fuel_flow_ad {
      position: absolute;
      top: 91.4%;
      right: 66%;
      text-align: right;
    }

    .cip_box1_pres {
      position: absolute;
      top: 91.5%;
      right: 60%;
      text-align: right;
    }

    .laval_ratio_box1 {
      position: absolute;
      top: 91.5%;
      right: 56%;
      text-align: right;
    }

    .cip_box2_pres {
      position: absolute;
      top: 91.5%;
      right: 50.5%;
      text-align: right;
    }

    .laval_ratio_box2 {
      position: absolute;
      top: 91.5%;
      right: 45.5%;
      text-align: right;
    }

    .fuel_press_ad {
      
      position: absolute;
      top: 92.3%;
      right: 85.8%;
      text-align: right;
    }

    .cg_pot_box1_temp_left
    {
      position: absolute;
      top: 71%;
      right: 78.8%;
      text-align: right;
    }
    .cg_cot_temp_ad_new
    {
      position: absolute;
      top: 71%;
      right: 73.2%;
      text-align: right;
    }

    .fuel_temp_ad {
      position: absolute;
      top: 92.3%;
      right: 90.8%;
      text-align: right;
    }

    .fuel_flow_eh {
      position: absolute;
      top: 91.4%;
      right: 38%;
      text-align: right;
    }

    .flue_gas3_temp {
      position: absolute;
      top: 44%;
      right: 73.5%;
      text-align: right;
    }

    .fuel2_temp {
      position: absolute;
      top: 7.9%;
      right: 60.5%;
      text-align: right;
    }

    .bundle2_temp {
      position: absolute;
      top: 7.9%;
      right: 55.2%;
      text-align: right;
    }

    .bundle1_dss_temp
    {
      position: absolute;
      top: 20%;
      right: 68%;
      text-align: right;
    }
    .bundle2_dss_temp
    {
      position: absolute;
      top: 8.3%;
      right: 44.2%;
      text-align: right;
    }

    .cg_cot_temp_eh {
      position: absolute;
      top: 71%;
      right: 31.2%;
      text-align: right;
    }

    .cg_cop_press {
      position: absolute;
      top: 65.3%;
      right: 13.2%;
      text-align: right;
    }

    .cg_sot_temp {
      position: absolute;
      top: 73%;
      right: 5.5%;
      text-align: right;
    }

    .quench_flow_pri
    {
      position: absolute;
      top: 84%;
      right: 8%;
      text-align: right;
    }

    .quench_flow_sec
    {
      position: absolute;
      top: 84%;
      right: 3%;
      text-align: right;
    }
    .cg_pot_temp_eh {
      position: absolute;
      top: 70.7%;
      right: 26.2%;
      text-align: right;
    }

    .cg_pop_pressure {
      position: absolute;
      top: 70.7%;
      right: 20.2%;
      text-align: right;
    }

    .cg_pop_pressure_v2 {
      position: absolute;
      top: 71.2%;
      right: 20%;
      text-align: right;
    }

    .bundle_press_eh {
      position: absolute;
      top: 58.3%;
      right: 43.5%;
      text-align: right;
    }

    .bundle_temp_eh {
      position: absolute;
      top: 58.3%;
      right: 38.5%;
      text-align: right;
    }

    .excess_air_eh_o2{
      position: absolute;
      top: 56.3%;
      right: 33.5%;
      text-align: right;
    }

    .hp_stream_press {
      position: absolute;
      top: 53.9%;
      right: 21.7%;
      text-align: right;
    }

    .hp_stream_temp {
      position: absolute;
      top: 53.9%;
      right: 16.6%;
      text-align: right;
    }

    .hp_stream_flow
    {
      position: absolute;
      top: 58%;
      right: 33.5%;
      text-align: right;
    }

    .feed_flow_eh_1 {
      position: absolute;
      top: 29.6%;
      right: 26.8%;
      text-align: right;
    }

    .dilut_temp_eh {
      position: absolute;
      top: 40.6%;
      right: 23.4%;
      text-align: right;
    }

    .feed_flow_eh {
      position: absolute;
      top: 18.6%;
      right: 38.2%;
      text-align: right;
    }

    .feed_press_eh {
      position: absolute;
      top: 18.6%;
      right: 28%;
      text-align: right;
    }

    .feed_temp_eh {
      position: absolute;
      top: 18.6%;
      right: 23.4%;
      text-align: right;
    }

    .flue_gas_temp_eh {
      position: absolute;
      top: 8.4%;
      right: 23.4%;
      text-align: right;
    }

    .flue_gas_excess_air {
      position: absolute;
      top: 8.4%;
      right: 28.5%;
      text-align: right;
    }

    .excess_air_min_o2{
      display:none;
      position: absolute;
      top: 80.3%;
      right: 50.5%;
      text-align: right;
    }

    .firedHeat{
      position: absolute;
      top: 77.1%;
      right: 50.5%;
      text-align: right;
    }

    .cg_cop2_press{
      position: absolute;
      top: 62.3%;
      right: 5.5%;
      text-align: right;
    }

    .tag_name {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 8.6%;
      left: 82%;
    }

    .bottom_mid_name {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 73.6%;
      left: 45.35%;
    }

    .bottom_right_name {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 9.6%;
      left: 83.6%;
    }

    .bottom_right_name_v2 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 90%;
      left: 81%;
    }

    .bottom_right_name_2 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 90.9%;
      left: 82.5%;
    }

    .bottom_left_barell {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 81.9%;
      left: 9.8%;
    }

    .bottom_right_barell {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 87.9%;
      left: 67.25%;
    }

    .quench {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 73%;
      left: 85.25%;
      transform: rotate(270deg);
    }

    .feed_type_box1 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 14.5%;
      left: 0.7%;
      line-height: 1.8;

      p:first-child {
        position: relative;
        top: rem-calc(-4);
        text-shadow: 2px 1px rgba(0, 0, 0, 0.125);

        + p {
          padding-left: rem-calc(4);
        }
      }

      p:last-child {
        font-weight: normal;
      }
    }

    .feed_type_box2 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      text-align: right;
      top: 14.5%;
      right:9.5%;
      line-height: 1.8;

      p:first-child {
        position: relative;
        top: rem-calc(-4);
        text-shadow: 2px 1px rgba(0, 0, 0, 0.125);

        + p {
          padding-right: rem-calc(8);
        }
      }
    }

    .weather {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 57.2%;
      left: 1.1%;
    }

    .checkbox {
      position: absolute;
      top: 68.5%;
      left: 0.5%;
      text-align: left;
    }

    .hidden {
      visibility: hidden;
    }

    .noEdit {
      pointer-events: none;
    }
  }
}
