.message-background {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
}

.message-container {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: rem-calc(20);

  .message-text {
    &.error {
      color: $colorShutDown;
    }
  }

  .button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: rem-calc(20);
  }
}

.message-scenario-container {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: rem-calc(20);

  .message-text {
    &.error {
      color: $colorShutDown;
    }
  }

  .button {
    position: relative;
    left: 5%;
    transform: translateX(0%);
    margin-top: rem-calc(20);
  }
}
