.drawer-menu-icon {
  color: $secondary-color;
}

#drawer-menu {
  width: 300px;

  .drawer-menu-item {
    text-decoration: none;
    color: $secondary-color;
  }

  .is-active {
    background-color: $secondary-color;
    color: white;
  }

  .is-disabled {
    color: lightgray;
    pointer-events: none;
  }
}
