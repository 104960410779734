$contentWidth: 1336px;

.page-container {
  position: relative;
}

.displ-hide {
  display: none;
}
.overall-dahej {
  position: absolute;
  background-image: none;
  width: $contentWidth;
  height: 900px;

  .furnace-overview-dahej {
    background-repeat: no-repeat;
    position: relative;
    width: $contentWidth;
    height: 808px;
    color: rgb(51, 51, 51);

    .furnace-overview--wrapper {
      position: relative;
      width: 100%;
      height: 808px;
      background-repeat: no-repeat;
    }

    .furnace-img-1 {
      background-image: url("../images/dahej/furnace-overview_Furnaces_1.svg");
    }
    .furnace-img-2 {
      background-image: url("../images/dahej/furnace-overview_Furnaces_2.svg");
    }
    .furnace-img-3 {
      background-image: url("../images/dahej/furnace-overview_Furnaces_3.svg");
    }
    .furnace-img-4 {
      margin-left: 5px;
      background-image: url("../images/dahej/furnace-overview_Furnaces_4.svg");
    }
    .furnace-img-5 {
      margin-left: 5px;
      background-image: url("../images/dahej/furnace-overview_Furnaces_5.svg");
    }
    .furnace-img-6 {
      margin-left: 5px;
      background-image: url("../images/dahej/furnace-overview_Furnaces_6.svg");
    }
    .furnace-img-7 {
      margin-left: 5px;
      background-image: url("../images/dahej/furnace-overview_Furnaces_7.svg");
    }
    .furnace-img-8 {
      margin-left: 5px;
      background-image: url("../images/dahej/furnace-overview_Furnaces_8.svg");
    }
    .boild_feed_water_flow {
      position: absolute;
      top: 4.5%;
      right: 83.5%;
      text-align: right;
    }

    .boild_feed_water_press {
      position: absolute;
      top: 4.5%;
      right: 78.7%;
      text-align: right;
    }

    .boild_feed_water_temp {
      position: absolute;
      top: 4.5%;
      right: 73.7%;
      text-align: right;
    }

    .feed_flow_ad {
      position: absolute;
      top: 16.6%;
      right: 83.6%;
      text-align: right;
    }

    .feed_flow_tag {
      position: absolute;
      top: 12.5%;
      right: 85.3%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_flow_pres {
      position: absolute;
      top: 12.5%;
      right: 80.3%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_flow_temp {
      position: absolute;
      top: 12.5%;
      right: 75.3%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_flow2_tag {
      position: absolute;
      top: 12.5%;
      right: 34.7%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_flow2_pres {
      position: absolute;
      top: 12.5%;
      right: 29.7%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_flow2_temp {
      position: absolute;
      top: 12.5%;
      right: 24.7%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_press_ad {
      position: absolute;
      top: 16.6%;
      right: 79.1%;
      text-align: right;
    }

    .feed_temp_ad {
      position: absolute;
      top: 16.6%;
      right: 73.7%;
      text-align: right;
    }

    .dilut_flow_ad {
      position: absolute;
      top: 31.6%;
      right: 83.6%;
      text-align: right;
    }

    .dilut_press_ad {
      position: absolute;
      top: 31.6%;
      right: 79%;
      text-align: right;
    }

    .dilut_temp_ad {
      position: absolute;
      top: 31.6%;
      right: 73.7%;
      text-align: right;
    }

    .boild_feed_water_desuper {
      display: none;
      position: absolute;
      top: 53%;
      right: 83.2%;
      text-align: right;
    }

    .bundle3_temp {
      position: absolute;
      top: 52.8%;
      right: 84.2%;
      text-align: right;
    }

    .bundle4_temp {
      position: absolute;
      top: 52.8%;
      right: 79.1%;
      width: rem-calc(44);
      text-align: right;
    }

    .cg_pot_temp_ad {
      position: absolute;
      top: 69.7%;
      right: 78.7%;
      text-align: right;
    }

    .cg_cot_temp_ad {
      position: absolute;
      top: 69.7%;
      right: 73%;
      text-align: right;
    }

    .bundle_press_ad {
      position: absolute;
      top: 56.3%;
      right: 65.8%;
      text-align: right;
    }

    .bundle_temp_ad {
      position: absolute;
      top: 56.3%;
      right: 61.9%;
      text-align: right;
    }

    .excess_air_ad_o2 {
      position: absolute;
      top: 56.3%;
      right: 72.1%;
      text-align: right;
    }

    .flue_gas_press {
      position: absolute;
      top: 64.5%;
      right: 55.5%;
      text-align: right;
    }

    .flue_gas_ad_temp {
      position: absolute;
      top: 64.5%;
      right: 49.5%;
      text-align: right;
    }

    .fired_heat {
      position: absolute;
      top: 77%;
      right: 52%;
      text-align: right;
    }

    .fuel_flow_ad {
      position: absolute;
      top: 91.1%;
      right: 66%;
      text-align: right;
    }

    .cip_box1_pres {
      position: absolute;
      top: 90.5%;
      right: 59%;
      text-align: right;
    }

    .laval_ratio_box1 {
      position: absolute;
      top: 90.5%;
      right: 55%;
      text-align: right;
    }

    .cip_box2_pres {
      position: absolute;
      top: 90.5%;
      right: 49.5%;
      text-align: right;
    }

    .laval_ratio_box2 {
      position: absolute;
      top: 90.5%;
      right: 45.5%;
      text-align: right;
    }

    .fuel_press_ad {
      position: absolute;
      top: 91.3%;
      right: 89.8%;
      text-align: right;
    }

    .fuel_temp_ad {
      position: absolute;
      top: 91.3%;
      right: 85%;
      text-align: right;
    }

    .fuel_flow_eh {
      position: absolute;
      top: 91.1%;
      right: 38%;
      text-align: right;
    }

    .flue_gas3_temp {
      position: absolute;
      top: 36.5%;
      right: 62.5%;
      text-align: right;
    }

    .fuel2_temp {
      position: absolute;
      top: 22.6%;
      right: 62.5%;
      text-align: right;
    }

    .bundle2_temp {
      position: absolute;
      top: 8.3%;
      right: 62.2%;
      text-align: right;
    }

    .bundle1_dss_temp {
      position: absolute;
      top: 20%;
      right: 68%;
      text-align: right;
    }
    .bundle2_dss_temp {
      position: absolute;
      top: 8.3%;
      right: 44.2%;
      text-align: right;
    }

    .cg_cot_temp_eh {
      position: absolute;
      top: 70%;
      right: 30.2%;
      text-align: right;
    }

    .cg_cop_press {
      position: absolute;
      top: 65.3%;
      right: 13.2%;
      text-align: right;
    }

    .cg_sot_temp {
      position: absolute;
      top: 69.8%;
      right: 6.2%;
      text-align: right;
    }

    .quench_flow_pri {
      position: absolute;
      top: 84%;
      right: 8%;
      text-align: right;
    }

    .quench_flow_sec {
      position: absolute;
      top: 84%;
      right: 3%;
      text-align: right;
    }
    .cg_pot_temp_eh {
      position: absolute;
      top: 70%;
      right: 26.2%;
      text-align: right;
    }

    .cg_pop_pressure {
      position: absolute;
      top: 65.7%;
      right: 16.2%;
      text-align: right;
    }

    .cg_pop_pressure_v2 {
      position: absolute;
      top: 70.2%;
      right: 20%;
      text-align: right;
    }

    .bundle_press_eh {
      position: absolute;
      top: 56.3%;
      right: 43.5%;
      text-align: right;
    }

    .bundle_temp_eh {
      position: absolute;
      top: 56.3%;
      right: 38.5%;
      text-align: right;
    }

    .excess_air_eh_o2 {
      position: absolute;
      top: 56.3%;
      right: 33.5%;
      text-align: right;
    }

    .hp_stream_press {
      position: absolute;
      top: 51.4%;
      right: 21.7%;
      text-align: right;
    }

    .hp_stream_temp {
      position: absolute;
      top: 51.4%;
      right: 17.5%;
      text-align: right;
    }

    .hp_stream_flow {
      position: absolute;
      top: 51.6%;
      right: 26.5%;
      text-align: right;
    }
    .dilut_eh {
      position: absolute;
      top: 31.6%;
      right: 32.9%;
      text-align: right;
    }

    .dilut_press_eh {
      position: absolute;
      top: 31.6%;
      right: 28%;
      text-align: right;
    }

    .dilut_temp_eh {
      position: absolute;
      top: 31.6%;
      right: 23.4%;
      text-align: right;
    }

    .feed_flow_eh {
      position: absolute;
      top: 16.6%;
      right: 32.9%;
      text-align: right;
    }

    .feed_press_eh {
      position: absolute;
      top: 16.6%;
      right: 28%;
      text-align: right;
    }

    .feed_temp_eh {
      position: absolute;
      top: 16.6%;
      right: 23.4%;
      text-align: right;
    }

    .flue_gas_temp_eh {
      position: absolute;
      top: 5.4%;
      right: 23.4%;
      text-align: right;
    }

    .flue_gas_excess_air {
      position: absolute;
      top: 5.4%;
      right: 28.5%;
      text-align: right;
    }

    .excess_air_min_o2 {
      display: none;
      position: absolute;
      top: 80.3%;
      right: 50.5%;
      text-align: right;
    }

    .firedHeat {
      position: absolute;
      top: 77.1%;
      right: 50.5%;
      text-align: right;
    }

    .cg_cop2_press {
      position: absolute;
      top: 58.3%;
      right: 6.2%;
      text-align: right;
    }

    .tag_name {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 8.6%;
      left: 82%;
    }

    .bottom_mid_name {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 73.6%;
      left: 45.35%;
    }

    .bottom_right_name {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 77.6%;
      left: 83.6%;
    }

    .bottom_right_name_v2 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 90%;
      left: 81%;
    }

    .bottom_right_name_2 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 87.9%;
      left: 90.5%;
    }

    .bottom_left_barell {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 81.9%;
      left: 9.8%;
    }

    .bottom_right_barell {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 87.9%;
      left: 67.25%;
    }

    .quench {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 73%;
      left: 81.25%;
      transform: rotate(270deg);
    }

    .feed_type_box1 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 13%;
      left: 1.1%;

      p:first-child {
        position: relative;
        top: rem-calc(-4);
        text-shadow: 2px 1px rgba(0, 0, 0, 0.125);

        + p {
          padding-left: rem-calc(4);
        }
      }
    }

    .feed_type_box2 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      text-align: right;
      top: 13%;
      right: 9.3%;

      p:first-child {
        position: relative;
        top: rem-calc(-4);
        text-shadow: 2px 1px rgba(0, 0, 0, 0.125);

        + p {
          padding-right: rem-calc(8);
        }
      }
    }

    .weather {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 55.2%;
      left: 1.1%;
    }

    .checkbox {
      position: absolute;
      top: 69.5%;
      left: 1%;
      text-align: left;
    }

    .hidden {
      visibility: hidden;
    }

    .noEdit {
      pointer-events: none;
    }
  }
}
