.root {
    flex-grow: 1,
}

.scenario-simulator-overview {
    flex-direction: column;
}

.control-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;

    &.element {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        padding-right: 20px;
    }

    &.element.alignright {
        padding-right: 0px;
        margin-left: auto;
    }

}

.container-typography {
    width: 180%;
    padding-right: 10px;
}

.scenario-runlength-list {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.circularProgressbar {
    max-width: 100px;
    max-height: 100px;
}

.barLoader {
    min-width: 100px;
    min-height: 20px;
}

.scenario-card {
    padding-right: 10px;

    .scenario-button {
        &.UNCOMPUTED {
            background-color: rgb(30, 178, 197);
            color: white;
        }

        &.RUNNING {
            background-color: orange;
            color: black;
        }

        &.COMPUTED {
            background-color: green;
            color: white;
        }

        &.ERROR {
            background-color: red;
            color: white;
        }

        &.STOP_CALCULATION {
            background-color: gray;
            color: white;
        }

        &.RESTORE {
            background-color: gray;
            color: white;
        }

        &.IS_CHANGED_IN_FEEDSTOCK {
            background-color: orange;
            color: black;
        }

        &.IS_CHANGED_IN_FUELGAS {
            background-color: orange;
            color: black;
        }

        &.IS_CHANGED_IN_CONVECTION_SECTION {
            background-color: orange;
            color: black;
        }

        &.DELETED_FEEDSTOCK {
            background-color: orange;
            color: black;
        }

        &.DELETED_FUELGAS {
            background-color: orange;
            color: black;
        }

        &.DELETED_CONVECTION_SECTION {
            background-color: orange;
            color: black;
        }

        &.IS_DELETED_SCENARIO_CONFIG {
            background-color: orange;
            color: black;
        }

        &.IS_DELETED_SCENARIO_CONFIG_WITH_CONVECTION_SECTION {
            background-color: orange;
            color: black;
        }
    }

}

.scenario-runlength-card {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    position: relative;

    .calculating {
        overflow: visible;
        background-color: rgba(128, 128, 128, 0.486);
        max-height: 100%;
        min-height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
    }

    .paper {
        min-width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;
    }

    .scenario-runlength-content {
        display: inline-flex;
        flex-direction: row;
        min-width: 100%;
        border-color: green;

        .runlength {
            display: inline-flex;
            flex-direction: row;
            min-width: 100%;
        }
    }

    .right-column {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-width: 90%;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        Button {
            margin-left: 10px;
        }
    }
}

.MuiDialog-container {
    header {
        padding: 5px;
        height: auto;
        margin-top: 8px;
    }

    .MuiPaper-rounded {
        border-radius: 0px;
        padding: 0px 15px;
    }

    .MuiTabs-indicator {
        width: 50% !important;
    }
}

.autocomplete {

    display: inline-flex;

    .MuiFormControl-root {
        width: 150px;
        margin-top: 4px;
    }
}

.fireboxDisp {
    width: 49%;
    float: left;
    margin-top: 5px;

    span {
        font-weight: 700;
    }
}

.firebox1 {
    padding-right: 10px;
}

.fireboxCommonField {
    width: 48%;
    float: left;
    padding-right: 2%;
}

.commonConfigLabel
{
    width: 100%;

    span {
        font-weight: 700;
    }
}