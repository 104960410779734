$primary-color: rgb(210, 225, 235);
$primary-color-80: rgba(210, 225, 235, 0.8);
$secondary-color: rgb(0, 85, 145);
$secondary-color-50: rgba(0, 85, 145, 0.5);
$linde-lightgrey: rgb(241, 246, 249);
$font-color: rgb(51, 51, 51);

$white-color: rgb(255, 255, 255);
$black-color: rgb(0, 0, 0);
$colorBorder: rgb(150, 150, 150);
$disabled-color: rgba(168, 168, 168, 0.5);

/**
* Status colors
*/
$colorDefault: rgb(207, 207, 205);
$colorError: rgb(205, 0, 15);
$colorCracking: rgb(0, 155, 60);
$colorCrackingStandBy: rgb(0, 110, 42);
$colorDecoking: rgb(255, 165, 0);
$colorDecokingStandBy: rgb(180, 117, 0);
$colorShutDown: rgb(59, 59, 59);
