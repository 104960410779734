.notification-log{

    .high-severity{
        background-color: red;
        color: white !important;
        text-align: center !important;
        border: 5px solid white;  
    }
      
    .medium-severity{
        background-color: yellow;
        color: #000000 !important;
        text-align: center !important;
        border: 5px solid white;
    }
      
}