footer {
  height: $footer-height-small;
  padding: 0 $side-padding;
  flex-grow: 1;
  background-color: $primary-color;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0px;

  .footer-container {
    height: 100%;
  }

  .copyright {
    color: $secondary-color;
  }

  .footer-lable {
    color: $secondary-color;
  }
}
