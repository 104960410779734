html,
* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: $rem-base;
  height: 100%;
}

body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.42857;
  color: $font-color;
  background-color: $linde-lightgrey;
}

html,
body,
#root,
.App {
  height: 100%;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-container {
  align-self: start;
  overflow: auto;
  padding: rem-calc(20);
  height: calc(100% - 125px);
}

a {
  text-decoration: none;
}

.is-primary {
  color: rgba(210, 225, 235, 0.8);
}

.is-secondary {
  color: #005591;
}

.legend-item-1 {
  display: none !important;
}


.MuiToggleButton-root {
  color: black !important;
  border: 1px solid black !important;
  padding: 5px 20px !important;
}

.MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
  border-left: 0px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
}

.MuiToggleButtonGroup-groupedHorizontal:last-child {
  border-left: 0px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-right: 0px !important;
}

.MuiToggleButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.12) !important;
}

.loader-overlay
{
  margin-left: 50vw;
  position: absolute;
}
.chartGridContainerLoader
{
  opacity: 0.4;
}

.notification-buttons{
  display: flex;
  justify-content: space-between;
}

.notif-table{
  width: 100%;
}

.disabled{
  pointer-events: none;
  opacity: .48;
}