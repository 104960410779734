.login {
  //background-image: url("../customer_specific_data/login_screen.jpg");
  background-size: cover;
  // height: calc(100vh - #{$header-height-small});
  margin: 0 auto;
  padding: 0;
  text-align: center;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.35);

  .login-form {
    position: relative;
    top: 30%;
    display: block;
    padding: 48px;
    margin: 0 auto;
    max-width: fit-content;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    transform: translateY(-50%);
  }

  .button-user {
    border: 1px solid $secondary-color;
    background: $secondary-color;
    color: $white-color;
    display: flex;
    align-items: center;
    align-self: center;
    padding: rem-calc(5) rem-calc(35);
    width: rem-calc(140);
    margin: 16px auto 0;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:hover,
    &:focus {
      background-color: $primary-color;
      color: $secondary-color;
      outline: none;
    }

    .button-user-logo {
      display: inline-block;
      background: url("../images/microsoft_auth_icon.svg") no-repeat;
      width: rem-calc(20);
      height: rem-calc(20);
      margin-right: rem-calc(10);
    }

    .button-user-logo-pingid {
      display: inline-block;
      background: url("../images/pingid_auth_icon.png") no-repeat;
      background-size: rem-calc(20) rem-calc(20);
      width: rem-calc(20);
      height: rem-calc(20);
      margin-right: rem-calc(10);
    }

  }
}
