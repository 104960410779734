.readonly-text{
    border: 0;
    text-align: center;
}

.notification-configuration-table{
    border: 1px solid #e0e0e0;
    table-layout: fixed;
    overflow-x: clip;

    .MuiTableCell-root{
        padding: 10px 0px;
    }

    .table-labels{
        font-weight: bold;
    }
}

.rule-name{
    text-transform: capitalize;
    font-weight:bold !important;
}

.notification-table-row{
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

