.convection-section-dashboard {

  .convection-section-table-wrapper {
    max-height: rem-calc(440);

    overflow: auto;
  }

  table {
    table-layout   : fixed;
    border-collapse: separate;
    border-spacing : 0;
    border-right   : 1px solid $secondary-color;
    width          : 100%;
  }

  th,
  td {
    border-top : 1px solid $secondary-color;
    border-left: 1px solid $secondary-color;
    box-sizing : border-box;
    text-align : center;
  }

  tbody {
    tr:last-child td {
      border-bottom: 1px solid $secondary-color;
    }
  }

  tr {
    &:nth-child(odd) {
      background: rgba(210, 225, 235, 0.8)
    }

    &:hover,
    &.selected {
      background-color: $secondary-color-50;
    }
  }

  #convection-section-table {
    border-collapse: separate;
    border-spacing : 0;

    thead th {
      position       : sticky;
      top            : 0;
      z-index        : 2;
      background     : $primary-color;
      border-collapse: separate;
    }

    tbody tr:first-child td {
      top: rem-calc(20);
    }

    tr th:last-child,
    tr td:last-child {
      border-right: 0;
    }
  }

  .man_correction {
    background-color: rgba(236, 231, 163, 0.2);
    color           : #d45215;
    width           : 100%;
    border          : none;
    text-align      : center;
  }

  .button {
    margin-right: rem-calc(10);
  }

  .scenario-header {
    position: relative;
  }

  .scenario-buttons {
    position  : absolute;
    top       : rem-calc(2);
    cursor    : pointer;
    transition: color 0.25s ease-in-out;
  }

  .icon-floppy-disk {
    color       : $secondary-color;
    left        : rem-calc(10);
    margin-right: rem-calc(12);

    &.saved,
    &:hover {
      color: $colorCracking;
    }
  }

  .icon-bin {
    color      : $secondary-color;
    right      : rem-calc(10);
    margin-left: rem-calc(10);

    &:hover {
      color: $colorShutDown;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .noEdit {
    pointer-events: none;
    color         : $disabled-color;
  }

  .scenario-alias {
    margin-left: rem-calc(5);
  }

  .alias-input {
    margin-left: rem-calc(5);
  }

  .scenario-alias-input {
    display: inline-block;
    border : 0;
  }
}

.external_Param {
  table {
    border-collapse: collapse;
    width          : 100%;
    table-layout   : fixed;
  }

  table,
  th,
  td {
    border    : 1px solid $secondary-color;
    text-align: center;
  }

  tr:hover {
    background-color: #f5f5f5;
  }
}

.button-close {
  position: relative;
  top     : rem-calc(40);
  width   : rem-calc(150);

  .icon-stand-by {
    margin-right: rem-calc(10);
    font-size   : rem-calc(12);
    color       : #005591;
  }
}

#overview-table-full {
  tbody {
    height: 65vh;
  }
}

#overview-table-half {
  tbody {
    height: 44vh;
  }
}

#overview-table-one-third {
  tbody {
    height: 30vh;
  }
}

#overview-table-full,
#overview-table-half,
#overview-table-one-third {
  display: inline-grid;
  grid-template-areas:
    "head-fixed"
    "body-scrollable";

  thead {
    grid-area: head-fixed;
  }

  tbody {
    grid-area: body-scrollable;
    overflow : auto;
    width    : fit-content;
  }

  /* width */
  ::-webkit-scrollbar {
    width: rem-calc(10);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}