/** Dimensions **/
$rem-base: 14px;
$header-height-small: rem-calc(50);
$footer-height-small: rem-calc(40);
$side-padding: rem-calc(20);
$borderRadius: 2px;
$buttonHeight: rem-calc(30);

$breakpoint-medium: 768px;
$breakpoint-large: 1280px;
