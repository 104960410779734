/** Fonts **/
@import url("https://fonts.googleapis.com/css?family=Cuprum:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cuprum";
  font-weight: 400;
}

h1 {
  font-size: rem-calc(24);
  line-height: rem-calc(29);
  padding-bottom: rem-calc(15);
  text-transform: uppercase;
}

h2 {
  font-size: rem-calc(20);
  line-height: rem-calc(29);
  padding-bottom: rem-calc(20);
  text-transform: uppercase;
}

h3 {
  font-size: rem-calc(18);
  padding-bottom: rem-calc(20);
  text-transform: uppercase;
}

.bold {
  font-weight: 600 !important;
}
