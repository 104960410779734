@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?pxjktg");
  src: url("../fonts/icomoon.eot?pxjktg#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?pxjktg") format("truetype"), url("../fonts/icomoon.woff?pxjktg") format("woff"), url("../fonts/icomoon.svg?pxjktg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-floppy-disk:before {
  content: "\e962";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-exit:before {
  content: "\ea14";
}
