$contentWidth: 1336px;

.page-container
{
  position: relative;
}

.displ-hide
{
  display: none;
}
.overall-ruwais {
  position: absolute;
  background-image: none;
  width: $contentWidth;
  height: 900px;

  .furnace-overview-ruwais {
    background-repeat: no-repeat;
    position: relative;
    background-image: url("../images/ruwais/furnace-overview.svg");
    width: $contentWidth;
    height: 900px;
    color: rgb(51, 51, 51);    

    .furnace-overview--wrapper {
      position: relative;
      width: 1290px;
      height: 808px;
      background-repeat: no-repeat;
    }

    .boild_feed_water_flow {
      position: absolute;
      top: 10%;
      right: 84.1%;
      text-align: right;
    }

    .boild_feed_water_press {
      position: absolute;
      top: 10%;
      right: 79.7%;
      text-align: right;
    }

    .boild_feed_water_temp {
      position: absolute;
      top: 10%;
      right: 74.9%;
      text-align: right;
    }

    .feed_flow_ad {
      position: absolute;
      top: 22%;
      right: 84.2%;
      text-align: right;
    }

    .feed_flow_tag{
      position: absolute;
      top: 12.5%;
      right: 85.3%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    
    .feed_flow_pres{
      position: absolute;
      top: 12.5%;
      right: 80.3%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_flow_temp{
      position: absolute;
      top: 12.5%;
      right: 75.3%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    } 
    
    .feed_flow2_tag{
      position: absolute;
      top: 12.5%;
      right: 34.7%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    
    .feed_flow2_pres{
      position: absolute;
      top: 12.5%;
      right: 29.7%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    }

    .feed_flow2_temp{
      position: absolute;
      top: 12.5%;
      right: 24.7%;
      text-align: right;
      font-size: 9px;
      font-weight: 600;
    } 
    

    .feed_press_ad {
      position: absolute;
      top: 22%;
      right: 79.6%;
      text-align: right;
    }

    .feed_temp_ad {
      position: absolute;
      top: 22%;
      right: 75%;
      text-align: right;
    }

    .dilut_flow_ad {
      position: absolute;
      top: 37.2%;
      right: 84.2%;
      text-align: right;
    }

    .dilut_press_ad {
      position: absolute;
      top: 37.2%;
      right: 79.6%;
      text-align: right;
    }

    .dilut_temp_ad {
      position: absolute;
      top: 37.2%;
      right: 74.7%;
      text-align: right;
    }

    .boild_feed_water_desuper {
      //display: none;
      position: absolute;
      top: 52.4%;
      right: 84.2%;
      text-align: right;
    }

    .bundle3_temp{
      position: absolute;
      top: 52.8%;
      right: 84.2%;
      text-align: right;
    }

    .bundle4_temp{
      position: absolute;
      top: 52.8%;
      right: 79.1%;
      width: rem-calc(44);
      text-align: right;
    }


    .cg_pot_temp_ad {
      position: absolute;
      top: 75.2%;
      right: 79.2%;
      text-align: right;
    }

    .cg_cot_temp_ad {
      position: absolute;
      top: 75.2%;
      right: 74.2%;
      text-align: right;
    }

    .bundle_press_ad {
      position: absolute;
      top: 62%;
      right: 67.8%;
      text-align: right;
    }

    .bundle_temp_ad {
      position: absolute;
      top: 62%;
      right: 62.9%;
      text-align: right;
    }

    .excess_air_ad_o2{
      position: absolute;
      top: 56.3%;
      right: 72.1%;
      text-align: right;
    }

    .flue_gas_press {
      position: absolute;
      top: 70.5%;
      right: 57%;
      text-align: right;
    }

    .flue_gas_ad_temp {
      position: absolute;
      top: 70.5%;
      right: 52.2%;
      text-align: right;
    }

    .fired_heat {
      position: absolute;
      top: 96.7%;
      right: 54.2%;
      text-align: right;
    }

    .fuel_flow_ad {
      position: absolute;
      top: 96.5%;
      right: 67.5%;
      text-align: right;
    }

    .cip_box1_pres {
      position: absolute;
      top: 90.5%;
      right: 59%;
      text-align: right;
    }

    .laval_ratio_box1 {
      position: absolute;
      top: 90.5%;
      right: 55%;
      text-align: right;
    }

    .cip_box2_pres {
      position: absolute;
      top: 90.5%;
      right: 49.5%;
      text-align: right;
    }

    .laval_ratio_box2 {
      position: absolute;
      top: 90.5%;
      right: 45.5%;
      text-align: right;
    }

    .fuel_press_ad {
      position: absolute;
      top: 96.5%;
      right: 90.5%;
      text-align: right;
    }

    .fuel_temp_ad {
      position: absolute;
      top: 96.5%;
      right: 85.5%;
      text-align: right;
    }

    .fuel_flow_eh {
      position: absolute;
      top: 96.5%;
      right: 45%;
      text-align: right;
    }

    .flue_gas3_temp {
      position: absolute;
      top: 36.5%;
      right: 62.5%;
      text-align: right;
    }

    .fuel2_temp {
      position: absolute;
      top: 22.6%;
      right: 62.5%;
      text-align: right;
    }

    .bundle2_temp {
      position: absolute;
      top: 8.3%;
      right: 62.2%;
      text-align: right;
    }

    .bundle1_dss_temp
    {
      position: absolute;
      top: 20%;
      right: 68%;
      text-align: right;
    }
    .bundle2_dss_temp
    {
      position: absolute;
      top: 8.3%;
      right: 44.2%;
      text-align: right;
    }

    .cg_cot_temp_eh {
      position: absolute;
      top: 76.2%;
      right: 32.2%;
      text-align: right;
    }

    .cg_cop_press {
      position: absolute;
      top: 65.3%;
      right: 13.2%;
      text-align: right;
    }

    .cg_sot_temp {
      position: absolute;
      top: 75.8%;
      right: 9.2%;
      text-align: right;
    }

    .quench_flow_pri
    {
      position: absolute;
      top: 84%;
      right: 8%;
      text-align: right;
    }

    .quench_flow_sec
    {
      position: absolute;
      top: 84%;
      right: 3%;
      text-align: right;
    }
    .cg_pot_temp_eh {
      position: absolute;
      top: 75.2%;
      right: 26%;
      text-align: right;
    }

    .cg_pop_pressure {
      position: absolute;
      top: 93%;
      right: 20.7%;
      text-align: right;
    }

    .cg_pop_pressure_v2 {
      position: absolute;
      top: 70.2%;
      right: 20%;
      text-align: right;
    }

    .bundle_press_eh {
      position: absolute;
      top: 62%;
      right: 45.5%;
      text-align: right;
    }

    .bundle_temp_eh {
      position: absolute;
      top: 62%;
      right: 41.2%;
      text-align: right;
    }

    .excess_air_eh_o2{
      position: absolute;
      top: 56.3%;
      right: 33.5%;
      text-align: right;
    }

    .hp_stream_press {
      position: absolute;
      top: 57.2%;
      right: 27.2%;
      text-align: right;
    }

    .hp_stream_temp {
      position: absolute;
      top: 57.2%;
      right: 22.5%;
      text-align: right;
    }

    .hp_stream_flow
    {
      position: absolute;
      top: 57.2%;
      right: 31.7%;
      text-align: right;
    }
    .dilut_eh {
      position: absolute;
      top: 37.2%;
      right:36.5%;
      text-align: right;
    }

    .dilut_press_eh {
      position: absolute;
      top: 37.2%;
      right: 32%;
      text-align: right;
    }

    .dilut_temp_eh {
      position: absolute;
      top: 37.2%;
      right: 27.2%;
      text-align: right;
    }

    .feed_flow_eh {
      position: absolute;
      top: 14.2%;
      right: 36.6%;
      text-align: right;
    }

    .feed_press_eh {
      position: absolute;
      top: 14.2%;
      right: 31.9%;
      text-align: right;
    }

    .feed_temp_eh {
      position: absolute;
      top: 14.2%;
      right: 27.2%;
      text-align: right;
    }

    .flue_gas_temp_eh {
      position: absolute;
      top: 3.2%;
      right: 27.2%;
      text-align: right;
    }

    .flue_gas_excess_air {
      position: absolute;
      top: 85.8%;
      right: 54.5%;
      text-align: right;
    }

    .excess_air_min_o2{
      display:none;
      position: absolute;
      top: 80.3%;
      right: 50.5%;
      text-align: right;
    }

    .firedHeat{
      position: absolute;
      top: 77.1%;
      right: 50.5%;
      text-align: right;
    }

    .cg_cop2_press{
      position: absolute;
      top: 64.3%;
      right: 9.2%;
      text-align: right;
    }

    .tag_name {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 8.6%;
      left: 82%;
    }

    .bottom_mid_name {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 73.6%;
      left: 45.35%;
    }

    .bottom_right_name {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 83%;
      left: 79.2%;
    }

    .bottom_right_name_v2 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 90%;
      left: 81%;
    }

    .bottom_right_name_2 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 89.9%;
      left: 86.5%;
    }

    .bottom_left_barell {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 88.5%;
      left: 8.8%;
    }

    .bottom_right_barell {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 92.9%;
      left: 61.7%;
    }

    .quench {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 73%;
      left: 81.25%;
      transform: rotate(270deg);
    }

    .feed_type_box1 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 13.0%;
      left:1.1%;

      p:first-child {
        position: relative;
        top: rem-calc(-4);
        text-shadow: 2px 1px rgba(0, 0, 0, 0.125);

        + p {
          padding-left: rem-calc(4);
        }
      }
    }

    .feed_type_box2 {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      text-align: right;
      top: 13.0%;
      right:9.3%;

      p:first-child {
        position: relative;
        top: rem-calc(-4);
        text-shadow: 2px 1px rgba(0, 0, 0, 0.125);

        + p {
          padding-right: rem-calc(8);
        }
      }
    }

    .weather {
      position: absolute;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 9pt;
      color: #005591;
      top: 61.2%;
      left: 1.1%;
    }

    .checkbox {
      position: absolute;
      top: 75.5%;
      left: 1.0%;
      text-align: left;
    }

    .hidden {
      visibility: hidden;
    }

    .noEdit {
      pointer-events: none;
    }
  }
}
