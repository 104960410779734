.camera-status-dashboard-header {
  padding: 16px;
  position: relative;
  
  span{
    font-size: 16px;
  }
  .camera-status-dashboard-header-selected-camera{
      padding-left: 10px;
  }
}

.camera-status-dashboard {
  width: 100%;
  display: flex;  
  
  .camera-health-box
  {
    width: 50%;
    border-radius: 4px;
    text-align: center;
    padding: 20px;
    margin: 6px;
    margin-top: 2%;

    p{
      padding: 5px;

      span{
        font-weight: bold;
      }
    }
  }

  .box-2{
    width: 100%;
  }
  
}

.camera-status-grid-parent
  {
    display: flex;
    height: 35vh;
    align-items: center;
    justify-content: center;
  }

.camera-health-dashboard{

  .furnace-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 10px 0px;
    }

  .cam-detail-card{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12);
  }

  .cam-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  }

  .furnace-title {
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
  }

  .cam-content{
  margin-right: 10px;
  }

  .furnacesCameraFont{
  font-weight: 500;
  color: black;
  margin-right: 10px;
  float: inline-start;
  }


  .camStatus{
  display: flex;
  margin: 10px
  }

  .detailedStatus{
  font-weight: 400;
  color: black;
  padding: 5px 15px;
  float: right;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  background: white;
  }

  .legend{
  width: 12px;
  height: 12px;
  border-radius: 0%;
  margin-right: 5px;
  }

  .legend-green{
    background: green;
  }
  .legend-yellow{
    background: yellow;
  }
  .legend-red{
    background: red;
  }
  .legend-holder{
    display:inline-flex;
    float: right;
  }
  .legend-text{
    margin-right: 10px;
  }
  .common-legend{
    margin-top: 1%;
  }
  .detailed-status-btn{
    margin-left: 60%;
  }
  .alarmStatus{
  display:flex;
  align-items:center;
  margin-left: 6px;
  }

  .camera-loader-grid-parent
  {
    display: flex;
    height: 20vh;
    align-items: center;
    justify-content: center;
  }

  .camera-furnace{
    margin-bottom: 10px;
  }

}

.camera-health-back-button{
  position: absolute !important;
  bottom: 32%;
  right: 1%;
  text-decoration: underline;
}

.camera-health-back-link{
  font-weight: 400;
  color: black;
  padding: 5px 15px;
  float: right;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  background: white;
  
}

.legend{
  width: 12px;
  height: 12px;
  border-radius: 0%;
  margin-right: 5px;
}

.legend-green{
  background: green;
}
.legend-red{
  background: red;
}
.legend-holder{
  display: inline-flex;
  float: right;
  bottom: 24%;
  position: absolute;
  right: 0;
}
.legend-text{
  margin-right: 10px;
}
.common-legend{
  margin-top: 1%;
}