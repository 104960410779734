.css-ci1l8p-TimelineMain {
    position: relative !important;
}

#react-chrono-timeline header {
    padding: 0px;
    display: none;
}

.timeline-horizontal-container li {
    width: 20px;
    height: 20px;
}

.timeline-controls button {
    width: 35px;
    height: 35px;
    border-radius: 0px;
}

.feedstock-overview .timeline-controls {
    padding: 10px;
    background: none;
}

#timeline-main-wrapper {
    height: 20px;
}

#react-chrono-timeline .card-description {
    background: #d8e5ee;
    margin: 0px;
    width: 98%;
    padding: 1%;
}

.css-8v2xrm-MediaDetailsWrapper {
    background: #d8e5ee;
    border-radius: 0px !important;
}

.image-gallery {
    width: 640px;
    height: auto;
}

.imgGallery {

    float: left;

}

.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}

.image-gallery-icon {
    position: relative !important;
    padding: 15px 15px 15px 20px !important;
    color: #005591 !important;
}

.image-gallery-swipe {
    height: 480px;
}

.image-gallery-slides,
.image-gallery-image,
.image-gallery-slide {
    height: 100% !important;
}

.image-gallery {
    background: #e7e7e7;
}

.image-gallery-bullet {
    display: none !important;
}

.image-gallery-bullets {
    left: 24% !important;
    width: 90% !important;
    bottom: 25px !important;
}

.image-gallery-bullet:nth-child(1n) {
    display: block !important;
    float: left;
}

.image-gallery {
    float: left;
}


.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    width: 20px !important;
    height: 20px !important;
}

.image-gallery-bullet:not(.image-gallery-bullet.active ~ .image-gallery-bullet) {
    transform: none;
    border: 1px solid #005591 !important;
    background: #005591 !important;
}

.image-gallery-bullets .image-gallery-bullet {
    border-radius: 0px !important;
    margin: 0px 0px !important;
    width: 1px !important;
    box-shadow: none !important;
    padding: 2px 0.5px !important;
    background-color: white !important;
    border: 1px solid transparent !important;
}

.image-gallery-bullet:nth-child(100n) {
    background: #ffc837 !important;
    border: #ffc837 1px solid !important;
    z-index: 99;
}

.image-gallery-icon {
    filter: none !important;
    left: 7% !important;
}

.sidepanel {
    background: white;
    width: calc(100% - 640px);
    float: left;
    height: 533px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 1px rgba(0, 0, 0, 0.12);
}

.color_box {
    height: 200px;
    width: 20px;
    background: linear-gradient(#FF0000, orange, yellow, green, #0000FF);
    margin-right: 10px;
    box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.4);
    float: left;
}

.color_box_gallery {
    height: 20px;
    width: 200px;
    background: linear-gradient(90Deg,#0000FF, green, yellow, orange, #FF0000);
    margin-right: 10px;
    box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.4);
    float: left;
}

.color_box_data {
    p:last-child {
        top: 165px;
        position: relative;
    }
}

.image-gallery {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px 1px rgba(0, 0, 0, 0.12);
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    position: absolute !important;
    // bottom: -25px !important;
    top: 95% !important;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    .image-gallery-svg{
        color: #000000;
    }
}

.maxTempMark,
.maxTempMarkLatest {
    display: flex;
    border-radius: 50%;
    border-style: solid;
    border-width: 5px;
    border-color: red;
    z-index: 1;
    position: absolute;
}

.minTempMark,
.minTempMarkLatest {
    display: flex;
    border-radius: 50%;
    border-style: solid;
    border-width: 5px;
    border-color: green;
    position: absolute;
    z-index: 1;
}

.cameraSelector {
    width: 100%;
    display: flex;
}

.latestImg {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    float: left;
    margin-bottom: 30px;
}

.latestImg p {
    width: 100%;
}

.cameraSelectorTag {
    width: 400px;
}

.latestImgDescription {
    width: 100%;
    display: flex;
}

.noImageCard {
    padding: 20px;
    width: 98%
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 480px !important;
}


.Imagetooltip {
    position: absolute;
    color: #fff;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    transform: translate3d(-50%, -50%, 0);
    border-radius: 5px;
    pointer-events: none;
    z-index: 1000;
}

.center-highlight {
    display: flex;
    position: absolute;
    border-color: purple;
    border-style: solid;
    border-width: 0px;
    color: purple;
    height: 1px;
    border-radius: 4px;
}

.left-highlight {
    display: flex;
    position: absolute;
    height: 1px;
    border-width: 0px 0px 0px 3px;
    border-style: solid;
    border-color: purple;
}

.left-highlight.inlet,
.right-highlight.inlet {
    border-color: black;
}

.right-highlight {
    display: flex;
    position: absolute;
    color: purple;
    border-style: solid;
    border-width: 0px 3px 0px 0px;
    height: 1px;
}

.right-highlight-parent {
    position: absolute;
}

.left-highlight-parent {
    position: absolute;
}

.center-highlight-parent {
    position: absolute;
}

.center-highlight:nth-child(24n) {
    border-width: 1px;
}

.center-temp-box {
    // width: 80px;
    position: absolute;
    display: flex;
    padding: 5px;
    font-weight: 700;
    border-radius: 8px;
}

.datetpicker_parent {
    float: left;

    >.MuiFormControl-root {
        float: left;
    }
}

.sidepanel-latest {
    height: 480px !important;

}

.recharts-wrapper {
    display: flex;
    position: relative;
}

.chartParent {
    width: 100%;
    height: 550px;
    display: flex;
    position: relative;
    margin-top: -15px;
}

.MuiDialog-container .MuiPaper-rounded {
    border-radius: 0px;
    padding: 0px 0px !important;
}

.MuiPickersModal-dialog {
    padding: 0px !important;
}

.recharts-dot {
    display: none;
}

.datetimeText {
    float: right;
    padding-right: 20px;
}

.recharts-legend-item.legend-item-1 {
    display: inline-block !important;
}

.btnNow {
    padding: 15px 15px 0px 15px;
    display: flex;
}

.inletCoilHighlight {
    width: 12px;
    height: 23px;
    margin-right: 10px;
    display: flex;
    float: left;
    margin-top: 10px;
    padding: 4px;
    color: black;
    border-radius: 4px;
    width: 25px;
    border: 1px solid grey;
    font-weight: bold;
}

.outletCoilHighlight {
    width: 12px;
    height: 23px;
    margin-right: 10px;
    display: flex;
    float: left;
    margin-top: 10px;
    padding: 4px;
    color: purple;
    border-radius: 4px;
    width: 25px;
    border: 1px solid grey;
    font-weight: bold;
}

.legends b {
    float: left;
    padding-right: 15px;
    margin-top: 15px;
}

.legends {
    display: flex;
    width: 100%;
}

.latestImgLoader {
    float: left;
    height: 480px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: fixed;
    width: 640px;
}

.image-gallery-image.opacity {
    opacity: 0.5;
}

.paperLatestImg {
    width: 905px;
    padding: 10px;
    height: 560px;
    position: relative;
    margin-right: 10px;
}

.paperLatestImgGraph {
    width: calc(100% - 950px);
    float: right;
    position: relative;
    height: 560px;
    padding: 10px;
    min-width: 905px;
}

.latestImgP {
    margin-bottom: 10px;
    font-weight: bold;
}

.paperGallery {
    position: absolute;
    margin-top: 10px;
    padding: 10px;
    min-width: 905px;
    max-height: 640px;
    // display: flex;
    max-width: 1060px;
    margin-bottom: 10px;
}

.temperatureChart{
    padding: 8px 24px 33px 183px !important;
}

.galleryMinTempValue{
    position: absolute !important;
    top: 40px !important;
    left: 194px;
}

.galleryMaxTempValue{
    top: 40px !important;
    position: absolute !important;
    left: 360px;
}

.galleryTitle{
    position: absolute;
    left: 10px;
    font-weight: bold;
}

.loader-grid-gallery {
    height: 480px !important;
    width: 905px !important;
}

.parentLatestImg {
    width: 100%;
    display: flex;
}

.seperatorHr {
    margin-top: 180px;
}

.sidePanelGallery {
    width: 265px;
}

.latestImageLoading {
    height: 530px !important;
}

.btnNavigation button {
    border: none !important;
}

.btnNavigation svg {
    font-size: 30px !important;
}

.btnNavigations {
    width: 640px;
    display: flex;
    text-align: center;
    margin: auto;
}

.materialUITooltip {
    padding: 0px !important;
    margin-bottom: 3px;
}

.controlPanelTI {
    padding: 10px;
    margin-bottom: 10px;
}

.editPanel {
    float: right;
    // padding: 10px ;
    display: flex;
}

.btnEdit {
    padding: 15px 15px 0px 15px;

}

.editTextField {
    margin-right: 10px !important;
}

.editPanelBtn {
    padding: 15px 15px 0px 15px !important;
   
}

.editPanelBtn button {
margin-right: 15px !important;
}

.tooltipTempRange{
    display: inline-flex;
}
.TMTInfoBlock {
    padding: 10px;
    margin-bottom: 8px;
    margin-top: 10px;
}

.TMTInfoFurnace {
    display: flex;
    align-items: left;
    justify-content: left;
    width: 30%;
}

.tmtgen{
    padding-right:0 !important;
}

.edit_assignment{
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin-bottom: 4px;
}

.sel_camera{
    width:200px;
}

.cam-typography {
    padding-right: 10px;
}

.camera_location{
    margin-left: 20px;
}

.camera_loc{
    margin-top: 10px;
}

.image-gallery-slide .image-gallery-description {
    right: 0;
    left: unset !important;
    bottom: 0px !important;
}

.image-gallery-left-nav {
    left: 0 !important;
}

.image-gallery-right-nav .image-gallery-svg,
.image-gallery-left-nav .image-gallery-svg {
    width: 10px !important;
    height: 20px !important;
}

.image-gallery-image-holder{
    width: 640px;
    height: 480px;
}

.dynamic-days-slider{
    margin-top: 21px;
}

.image-gallery-right-nav {
    left: 16% !important;
    right: unset !important;
}


.galleryDatePickers{
    margin-left: 35%;
    display: flex;
}

.galleryEndDatePicker{
    margin-left: 5%;
}